import { graphql, useStaticQuery } from "gatsby";
export const useServicesContentQuery = () => {
    const data = useStaticQuery(graphql`
    query {
        contentfulServices {
         	serviceSectionTile
            service1Image {
                file {
                    url
                }
            }
        	service1Title
            service1Copy {
                raw
            }
            service2Image {
                file {
                    url
                }
            }
            service2Title
            service2Copy {
                raw
            }
            service3Image {
                file {
                    url
                }
            }
            service3Title
            service3Copy {
                raw
            }
            service4Image {
                file {
                    url
                }
            }
            service4Title
            service4Copy {
                raw
            }
        }
      }
    `);
    return data;
}