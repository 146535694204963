import { graphql, useStaticQuery } from "gatsby";
export const useSectionFeesAndDiscountsContentQuery = () => {
    const data = useStaticQuery(graphql`
    query {
        contentfulSectionFeesAndDiscounts {
         	title
          sectionContent {
              raw
          }
          sectionBackground {
              file {
                  url
              }
          }
        }
      }
    `);
    return data;
}