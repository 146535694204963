import React from "react";
import CookieConsent, { Cookies } from "react-cookie-consent";
import { Link } from 'gatsby';
import LogoPurpleImg from "../../static/images/logo-purple.png";

export class Footer extends React.Component {

  smoothScroll = (e) => {
    e.preventDefault();
    document.querySelector(e.target.hash).scrollIntoView({
      behavior: 'smooth'
    });
  }
  
  componentDidMount() {
    
  }
  cookieAccept() {
   Cookies.set('gatsby-gdpr-facebook-pixel', true, {expires: 365});
   Cookies.set('gatsby-gdpr-google-tagmanager', true, {expires: 365});
  }
  cookieDecline() {
    Cookies.remove('gatsby-gdpr-facebook-pixel');
    Cookies.remove('gatsby-gdpr-google-tagmanager');
  }
  render () {

      return <footer className="footer">
      
      <CookieConsent
        location="bottom"
        buttonText="Accept"
        disableStyles={true}
        declineButtonText="Decline"
        enableDeclineButton
        onAccept={this.cookieAccept}
        onDecline={this.cookieDecline}
        buttonClasses="cookie-pop__btn accept"
        declineButtonClasses="cookie-pop__btn decline"
        containerClasses="cookie-pop__container"
        contentClasses="cookie-pop__content"
      >
        <p>This website stores cookies on your computer. These cookies are used to collect information about how you interact with this website and allow us to remember you.
We use this information in order to improve and customize your browsing experience and for analytics and metrics about our visitors on this website.
<br/><br/>
If you decline, your information won’t be tracked when you visit this website. A single cookie will be used in your browser to remember your preference not to be tracked.
        
        </p>
      </CookieConsent>

        <div className="footer__inner">
          <div className="footer__top">
            <img src={LogoPurpleImg} alt="" />
            <ul>
              <li><Link onClick={(e) => this.smoothScroll(e)} to="#about">About</Link></li>
              <li><Link onClick={(e) => this.smoothScroll(e)} to="#services">Services</Link></li>
              <li><Link onClick={(e) => this.smoothScroll(e)} to="#contact">Contact</Link></li>
            </ul>
          </div>
          <hr />
          <div className="footer__bottom">
            <p>&copy; Rich taxation and accounting 2021</p>
          </div>
        </div>
      </footer>;
  }
}












