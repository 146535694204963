import { graphql, useStaticQuery } from "gatsby";

export const useAboutContentQuery = () => {
    const data = useStaticQuery(graphql`
    query {
      contentfulAbout {
         	title
        	sectionContent {
            raw
          }
      }
    }
    `);
    return data;
}