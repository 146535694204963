import React from "react";
import {useSectionContactContentQuery} from './contact-content';
import { BLOCKS, MARKS } from "@contentful/rich-text-types"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";

const Contact = () => {
  const data = useSectionContactContentQuery().contentfulSectionContact;
    
  let references = [];
  // const Bold = ({ children }) => <span className="bold">{children}</span>
  // const Text = ({ children }) => <p className="align-center">{children}</p>
  const options = {    
      renderNode: {
          [BLOCKS.EMBEDDED_ASSET]: (node) => {
          const source = references.find(i => i.contentful_id === node.data.target.sys?.id);
          const fileType = ['image', 'video', 'audio'].find(i => source.file.contentType.includes(i));
          switch (fileType) {
              case "image":
                  return <img src={source.file.url} alt={source.description} />;
              case "video": 
                  return (
                      <video width="100%" height="240" controls>
                          <source src={source.file.url} type="video/mp4" />
                          Your browser does not support the video tag.
                      </video>
                  );
              case "audio": 
                  return <audio src={source.file.url} controls />;
          
              default:
                  return <h5>Missing asset</h5>
          }},
      },
  }

  const encode = (data) => {
    return Object.keys(data)
        .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
        .join("&")
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    let myForm = document.querySelector(".form");
    let formData = new FormData(myForm);
    fetch('/', {
      method: 'POST',
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": e.target.getAttribute("name"),
        "first-name": formData.get('first-name'),
        "last-name": formData.get('last-name'),
        "email": formData.get('email'),
        "apointment-type": formData.get('apointment-type')
      })
    }).then(() => {
      let message = document.createElement('p');
      message.appendChild(data?.thankYouNote);
      myForm.innerHTML = message;
    }).catch((error) => {
      let message = document.createElement('p');
      message.appendChild(error);
      myForm.innerHTML = message;
    })
  };

  return <section id="contact" className="section-contact" style={{
      backgroundImage: `url(${data?.sectionBackground?.file.url})`,
    }}>
    <div className="section-contact__inner">
      <h2 data-sal="slide-down" data-sal-duration="500" data-sal-easing="ease">{data?.title}</h2>

      <div className="form-container">
      <form name="contact" method="post" data-netlify="true" data-netlify-honeypot="bot-field">
<input type="hidden" name="bot-field" />
          <div className="grid">
            <div className="grid__col--6">
              <input type="text" required placeholder="First Name" name="first-name" />
            </div>

            <div className="grid__col--6">
              <input type="text" required placeholder="Last Name" name="last-name" />
            </div>
          </div>
          <input type="email" required placeholder="Email Address" name="email" />

          <textarea name="wish" id="" cols="30" rows="10" placeholder="I wish to..."></textarea>
          
          <select required placeholder="Appointment Type" name="apointment-type">
            <option value="" selected disabled>Select Appointment Type</option>
            <option value="face-to-face">Face to Face</option>
            <option value="mobile-services">Mobile Services</option>
            <option value="online">Online</option>
            <option value="communication">Communication</option>

          </select>
          
          <button type="submit">Contact</button>
        </form>
      </div>

      
    </div>
  </section>
}


export default Contact

