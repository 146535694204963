import { graphql, useStaticQuery } from "gatsby";
export const useSectionReviewsContentQuery = () => {
    const data = useStaticQuery(graphql`
    query {
      contentfulSectionReviews {
        sectionTitle
        sectionSubtitle
        reviews {
          reviewAuthor
          reviewContent {
            reviewContent
          }
        }
      }
    }
    `);
    return data;
}