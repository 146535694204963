import { graphql, useStaticQuery } from "gatsby";
export const useServiceBusinessSetupAdvisoryContentQuery = () => {
    const data = useStaticQuery(graphql`
    query {
        contentfulServiceBusinessSetupAdvisory {
         	serviceTitle
            serviceDescription {
                raw
            }
            businessSetupBg {
                file {
                    url
                }
            }
        }
      }
    `);
    return data;
}