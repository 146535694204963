import React from "react";
import {useServiceBusinessSetupAdvisoryContentQuery} from './business-setup-content';
import { BLOCKS, MARKS } from "@contentful/rich-text-types"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";

const BusinessSetup = () => {
  const data = useServiceBusinessSetupAdvisoryContentQuery().contentfulServiceBusinessSetupAdvisory;
    
  let references = [];
  // const Bold = ({ children }) => <span className="bold">{children}</span>
  // const Text = ({ children }) => <p className="align-center">{children}</p>
  const options = {    
      renderNode: {
          [BLOCKS.EMBEDDED_ASSET]: (node) => {
          const source = references.find(i => i.contentful_id === node.data.target.sys?.id);
          const fileType = ['image', 'video', 'audio'].find(i => source.file.contentType.includes(i));
          switch (fileType) {
              case "image":
                  return <img src={source.file.url} alt={source.description} />;
              case "video": 
                  return (
                      <video width="100%" height="240" controls>
                          <source src={source.file.url} type="video/mp4" />
                          Your browser does not support the video tag.
                      </video>
                  );
              case "audio": 
                  return <audio src={source.file.url} controls />;
          
              default:
                  return <h5>Missing asset</h5>
          }},
      },
  }
  return <section className="section-business-setup">
      <div className="section-business-setup__left" style={{
      backgroundImage: `url(${data?.businessSetupBg?.file.url})`}}>
      </div>
      <div className="section-business-setup__right">
        <h3 className="section-business-setup__title" data-sal="slide-down" data-sal-duration="500" data-sal-easing="ease">{data?.serviceTitle}</h3>
          <div className="section-business-setup__content margin-bottom" data-sal="slide-down" data-sal-duration="500" data-sal-easing="ease">
            { documentToReactComponents(JSON.parse(data?.serviceDescription.raw), options) }
          </div>
      </div>
  </section>
}


export default BusinessSetup

