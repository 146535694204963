import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';
import favicon from '../../../static/favicon.png';
import logoGreenShare from '../../../static/favicon.png';
const SEO = () => {
    const { site } = useStaticQuery(
        graphql`
            query {
                site {
                    siteMetadata {
                        title
                        description
                        author
                        siteUrl
                    }
                }
            }
        `,
    );
    
    const socialCard = `${site.siteMetadata.siteUrl}${logoGreenShare}`;

    const twitterCard = 'summary';

    return (
        <Helmet
            htmlAttributes={{
                lang: 'en',
            }}
            title={site.siteMetadata.title}
            titleTemplate={`%s`}
            defaultTitle={site.siteMetadata.title}
            meta={[
                {
                    name: 'description',
                    content:site.siteMetadata.description,
                },
                {
                    property: `og:title`,
                    content: site.siteMetadata.title,
                },
                {
                    property: 'og:description',
                    content: site.siteMetadata.description,
                },
                {
                    property: 'og:url',
                    content: site.siteMetadata.siteUrl,
                },
                {
                    name: 'twitter:card',
                    content: twitterCard,
                },
                {
                    property: 'og:image',
                    content: socialCard,
                },
                {
                    property: `og:type`,
                    content: `website`,
                },
            ]}
            link={[
                {
                    rel: 'shortcut icon',
                    type: 'image/png',
                    href: `${favicon}`,
                },
            ]}
        />
    );
};

SEO.defaultProps = {
    description: ``,
};

SEO.propTypes = {
    description: PropTypes.string,
    title: PropTypes.string,
};

export default SEO;