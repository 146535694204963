import React from "react";
import {useSectionFeesAndDiscountsContentQuery} from './fees-discounts-content';
import { BLOCKS, MARKS } from "@contentful/rich-text-types"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";

const FeesDiscounts = () => {
  const data = useSectionFeesAndDiscountsContentQuery().contentfulSectionFeesAndDiscounts;
    
  let references = [];
  // const Bold = ({ children }) => <span className="bold">{children}</span>
  // const Text = ({ children }) => <p className="align-center">{children}</p>
  const options = {    
      renderNode: {
          [BLOCKS.EMBEDDED_ASSET]: (node) => {
          const source = references.find(i => i.contentful_id === node.data.target.sys?.id);
          const fileType = ['image', 'video', 'audio'].find(i => source.file.contentType.includes(i));
          switch (fileType) {
              case "image":
                  return <img src={source.file.url} alt={source.description} />;
              case "video": 
                  return (
                      <video width="100%" height="240" controls>
                          <source src={source.file.url} type="video/mp4" />
                          Your browser does not support the video tag.
                      </video>
                  );
              case "audio": 
                  return <audio src={source.file.url} controls />;
          
              default:
                  return <h5>Missing asset</h5>
          }},
      },
  }
  return <section id="fees" className="section-fees-discounts">
    <div className="section-fees-discounts__inner">
      <h2>{data?.title}</h2>
      <div className="grid">
        <div className="grid__col--6">
          <img src={data?.sectionBackground?.file.url} alt="" />
        </div>

        <div className="grid__col--6 margin-bottom" data-sal="slide-down" data-sal-duration="500" data-sal-easing="ease">
          { documentToReactComponents(JSON.parse(data?.sectionContent.raw), options) }
        </div>
      </div>
    </div>
  </section>
}


export default FeesDiscounts

