import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faFacebook, faInstagram, faLinkedin } from '@fortawesome/free-brands-svg-icons';
import {useHeroContentQuery} from './home-hero-content';

import IPA from "../../../static/images/IPA.png";
import MHA from "../../../static/images/MHA.png";
import Taxag from "../../../static/images/Taxag.png";

const HomeHero = () => {
    const data = useHeroContentQuery().contentfulHomePage;
        
    return <section className="home-hero" style={{
        backgroundImage: `url(${data?.heroImage?.file.url})`,
    }}>
        <div className="home-hero__container">
            <h1 className="home-hero__heading" data-sal="slide-down" data-sal-duration="500" data-sal-easing="ease">
                Tax Agent and Business Accountant<br />
                <span className="home-hero__heading--highlight">personal one-on-one</span><br />
                with <span className="home-hero__heading--highlight">flexible solutions</span>
            </h1>
            <div className="home-hero__trust">
                <div className="home-hero__trust-item" data-sal="slide-down" data-sal-duration="500" data-sal-easing="ease">
                    <img src={MHA} alt="" className="home-hero__trust-image" />
                </div>
                <div className="home-hero__trust-item" data-sal="slide-down" data-sal-duration="500" data-sal-easing="ease">
                    <img src={Taxag} alt="" className="home-hero__trust-image" />
                </div>
                <div className="home-hero__trust-item" data-sal="slide-down" data-sal-duration="500" data-sal-easing="ease">
                    <img src={IPA} alt="" className="home-hero__trust-image" />
                </div>
            </div>
        </div>
    </section>
}


export default HomeHero

