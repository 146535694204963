import React from "react";
import "../styles/index.scss";
import { graphql } from "gatsby";
import { HeaderNav } from '../components/header-nav';
import Seo from "../components/seo";
import HomeHero from "../components/home-hero/home-hero";
import { Footer } from "../components/footer";
import About from "../components/about/about";
import Services from "../components/services/services";
import ITReturns from "../components/income-tax-returns/income-tax-returns";
import BasGstPayg from "../components/bas-gst-payg/bas-gst-payg";
import BusinessSetup from "../components/business-setup/business-setup";
import FeesDiscounts from "../components/fees-discounts/fees-discounts";
import Reviews from "../components/reviews/reviews";
import Newsletters from "../components/newsletters/newsletters";
import Contact from "../components/contact/contact";

class HomePage extends React.Component {
  
  render() {
    return (
        <div className="container home">
            <Seo />
            <HeaderNav />
            <HomeHero/>
            <main>
              <About />
              <Services />
              <ITReturns />
              <BasGstPayg />
              <BusinessSetup />
              <FeesDiscounts />
              <Reviews />
              <Newsletters />
              <Contact />
            </main>
            <Footer/>
        </div>
    )
  }
}

export default HomePage

export const data = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
