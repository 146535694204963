import { graphql, useStaticQuery } from "gatsby";
export const useSectionContactContentQuery = () => {
    const data = useStaticQuery(graphql`
    query {
        contentfulSectionContact {
         	title
          sectionBackground {
              file {
                  url
              }
          }
          thankYouNote
        }
      }
    `);
    return data;
}