import { Link } from "gatsby";
import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faPhone } from '@fortawesome/free-solid-svg-icons';
import Logo from "../../static/images/logo-white.png";

export class HeaderNav extends React.Component {

    smoothScroll = (e) => {
        e.preventDefault();

        // Check if inside mobile nav menu
        if (window.innerWidth < 1024 && e.target.closest('.header')) {
          document.querySelector('.header .header__nav-toggle').click();
        }

        document.querySelector(e.target.hash).scrollIntoView({
          behavior: 'smooth'
        });
    }
    
    state = {toggle: false};
    toggleClass = () => {
        // e.preventDefault();
        this.setState(state => ({
            toggle: !state.toggle
        }), () => {
            if (typeof document !== `undefined`) {
                // Have to use this check for document undefined for the deploy to go through
                this.state.toggle ? document.body.classList.add('nav-open') : document.body.classList.remove('nav-open');
            }
        });
    }
    componentWillMount() {
        if (typeof document !== `undefined`) {
            document.body.classList.remove('nav-open');
        }
        
    }
    componentDidMount() {
        // if (typeof document !== `undefined`) {
        //     const hamburger = document.querySelector('.header__list');
        //     hamburger.querySelector('.header__list-link').addEventListener('click', () => {
        //         document.body.classList.remove('nav-open');
        //         this.toggleClass();
        //     });
        // }
    }
    render () {
        
        return <header className="header">
            <div className="header__inner">
                <Link to="/" className="header__logo">
                    {/* Contentful Replaceable: Website Logo (Light) */}
                    <img src={Logo} alt="" className="header__logo-img" />
                </Link>

                <div className={"header__list-container " + (this.state.toggle ? ' header__list-container--active' : '') }>
                    <ul className="header__contact-list">
                        <li className="header__contact-list-item"><a className="header__contact-list-item" href="mailto:enquiries@richtaxation.com.au"><FontAwesomeIcon icon={faEnvelope}  className="header__contact-list-icon"/> enquiries@richtaxation.com.au</a></li>
                        <li className="header__contact-list-item"><a className="header__contact-list-item" href="tel: 0418582178"><FontAwesomeIcon icon={faPhone}  className="header__contact-list-icon" /> 0418 582 178</a></li>
                    </ul>
                    <ul className="header__list">
                        <li className="header__list-item"><Link onClick={(e) => this.smoothScroll(e)} to="#about" className="header__list-link">About</Link></li>
                        <li className="header__list-item"><Link onClick={(e) => this.smoothScroll(e)} to="#services" className="header__list-link">Services</Link></li>
                        <li className="header__list-item"><Link onClick={(e) => this.smoothScroll(e)} to="#newsletters" className="header__list-link">Newsletters</Link></li>
                        <li className="header__list-item"><Link onClick={(e) => this.smoothScroll(e)} to="#fees" className="header__list-link">Fees </Link></li>
                        <li className="header__list-item"><Link onClick={(e) => this.smoothScroll(e)} to="#contact" className="header__list-link">Contact</Link></li>
                    </ul>
                </div>

                <div onClick={() => this.toggleClass()} className={"header__nav-toggle " + (this.state.toggle ? ' header__nav-toggle--active' : '') }>
                    <button className="header__nav-toggle-btn">
                        <div className="header__nav-toggle-inner"></div>
                    </button>
                </div>
            </div>
        </header>;
    }
}
