import { graphql, useStaticQuery } from "gatsby";
export const useHeroContentQuery = () => {
    const data = useStaticQuery(graphql`
    query {
      contentfulHomePage {
        metaTags
        description
        slug
        heroImage {
          file {
            url
          }
        }
      }
    }
    `);
    return data;
}