import { graphql, useStaticQuery } from "gatsby";

export const useNewslettersContentQuery = () => {
    const data = useStaticQuery(graphql`
    query { 
      contentfulSectionNewsletters {
        title
        newsletterIcon {
          file {
            url
          }
        }
        newsletterPdf {
          title
          file {
            url
          }
        }
      }
    }
    `);
    return data;
}