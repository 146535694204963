import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import {useSectionReviewsContentQuery} from './reviews-content';
import { BLOCKS, MARKS } from "@contentful/rich-text-types"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";

const Reviews = () => {
  const data = useSectionReviewsContentQuery().contentfulSectionReviews;
    
  let references = [];
  // const Bold = ({ children }) => <span className="bold">{children}</span>
  // const Text = ({ children }) => <p className="align-center">{children}</p>
  const options = {    
      renderNode: {
          [BLOCKS.EMBEDDED_ASSET]: (node) => {
          const source = references.find(i => i.contentful_id === node.data.target.sys?.id);
          const fileType = ['image', 'video', 'audio'].find(i => source.file.contentType.includes(i));
          switch (fileType) {
              case "image":
                  return <img src={source.file.url} alt={source.description} />;
              case "video": 
                  return (
                      <video width="100%" height="240" controls>
                          <source src={source.file.url} type="video/mp4" />
                          Your browser does not support the video tag.
                      </video>
                  );
              case "audio": 
                  return <audio src={source.file.url} controls />;
          
              default:
                  return <h5>Missing asset</h5>
          }},
      },
  }

  const changeSlide = (action) => {
    const slides = document.querySelectorAll('.slider .slider__slide');
    const activeSlide = document.querySelector('.slider .slider__slide--active');

    for (let i = 0; i < slides.length; i++) {
      if (slides[i].classList.contains('slider__slide--active')) {
        slides[i].classList.remove('slider__slide--active');

        if (action == 'prev') {
          if (i == 0) {
            slides[slides.length - 1].classList.add('slider__slide--active');
          } else {
            slides[i].previousElementSibling.classList.add('slider__slide--active');
          }
        } else {
          if (i == slides.length - 1) {
            slides[0].classList.add('slider__slide--active');
          } else {
            slides[i].nextElementSibling.classList.add('slider__slide--active');
          }
        }

        break;
      }
    }
  }

  return <section className="section-reviews">
    <div className="section-reviews__inner">
      <h2 data-sal="slide-down" data-sal-duration="500" data-sal-easing="ease">{data?.sectionTitle}</h2>
      <h2 data-sal="slide-down" data-sal-duration="500" data-sal-easing="ease"><strong>{data?.sectionSubtitle}</strong></h2>

      <div className="slider" data-sal="slide-down" data-sal-duration="500" data-sal-easing="ease">
        <div className="slider__inner">
          <div className="slider__control-prev">
            <button onClick={() => changeSlide('prev')} className="slider__control-prev-btn"><FontAwesomeIcon icon={faChevronLeft} /></button>
          </div>
          <div className="slider__slides">
            {
              data?.reviews.map((item, index) => {
                return <div className={"slider__slide " + (index == 0 ? ' slider__slide--active' : '')}>
                  <p className="text-center"><em>{item.reviewContent?.reviewContent}</em></p>
                  <p className="text-right"><strong>-{item.reviewAuthor}</strong></p>
                </div>
              })
            }
          </div>
          <div className="slider__control-next">
            <button onClick={() => changeSlide('next')} className="slider__control-next-btn"><FontAwesomeIcon icon={faChevronRight} /></button>
          </div>
        </div>
      </div>
    </div>
  </section>
}


export default Reviews

