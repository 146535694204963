import React from "react";
import {useServiceItReturnsContentQuery} from './income-tax-returns-content';
import { BLOCKS, MARKS } from "@contentful/rich-text-types"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";

const ITReturns = () => {
    const data = useServiceItReturnsContentQuery().contentfulServiceItReturns;
    
    let references = [];
    // const Bold = ({ children }) => <span className="bold">{children}</span>
    // const Text = ({ children }) => <p className="align-center">{children}</p>
    const options = {    
        renderNode: {
            [BLOCKS.EMBEDDED_ASSET]: (node) => {
            const source = references.find(i => i.contentful_id === node.data.target.sys?.id);
            const fileType = ['image', 'video', 'audio'].find(i => source.file.contentType.includes(i));
            switch (fileType) {
                case "image":
                    return <img src={source.file.url} alt={source.description} />;
                case "video": 
                    return (
                        <video width="100%" height="240" controls>
                            <source src={source.file.url} type="video/mp4" />
                            Your browser does not support the video tag.
                        </video>
                    );
                case "audio": 
                    return <audio src={source.file.url} controls />;
            
                default:
                    return <h5>Missing asset</h5>
            }}
        },
    }
    return <section className="section-it-returns">
        <div className="section-it-returns__left" style={{
        backgroundImage: `url(${data?.itReturnsBg?.file.url})`}}></div>
        <div className="section-it-returns__right">
            <h3 className="section-it-returns__title" data-sal="slide-down" data-sal-duration="500" data-sal-easing="ease">{data?.serviceTitle}</h3>
            <div className="section-it-returns__content margin-bottom" data-sal="slide-down" data-sal-duration="500" data-sal-easing="ease">
                { documentToReactComponents(JSON.parse(data?.serviceDescription.raw), options) }
            </div>
        </div>
    </section>
}


export default ITReturns

