import React from "react";
import {useNewslettersContentQuery} from './newsletters-content';
const Newsletters = () => {
  const data = useNewslettersContentQuery().contentfulSectionNewsletters;
  return <section id="newsletters" className="section-newsletters">
    <div className="section-newsletters__inner">
      <h2 data-sal="slide-down" data-sal-duration="500" data-sal-easing="ease">{data.title}</h2>
      <div className="grid">
        {
          data?.newsletterPdf.map((item, index, arr) => {
            if (arr.length < 4) {
              return <div className="grid__col--4" data-sal="slide-down" data-sal-duration="500" data-sal-easing="ease">
                <a href={item.file.url} target="_blank">
                  <img src={data?.newsletterIcon?.file.url} alt="" />
                </a>
                <h3>{item.title}</h3>
              </div>
            }
            if (arr.length === 4) {
              return <div className="grid__col--3" data-sal="slide-down" data-sal-duration="500" data-sal-easing="ease">
                <a href={item.file.url} target="_blank">
                  <img src={data?.newsletterIcon?.file.url} alt="" />
                </a>
                <h3>{item.title}</h3>
              </div>
            }
          })
        }
      </div>
    </div>
  </section>
}


export default Newsletters

