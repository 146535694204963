import { graphql, useStaticQuery } from "gatsby";
export const useServiceBasGstPaygContentQuery = () => {
    const data = useStaticQuery(graphql`
    query {
        contentfulServiceBasGstPayg {
         	serviceTitle
          serviceDescriptin {
              raw
          }
          serviceBasGstPayGBackground {
              file {
                  url
              }
          }
        }
      }
    `);
    return data;
}