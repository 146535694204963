import React from "react";
import {useServicesContentQuery} from './services-content';
import { BLOCKS, MARKS } from "@contentful/rich-text-types"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { fa } from '@fortawesome/free-solid-svg-icons';
const Services = () => {
    const data = useServicesContentQuery()?.contentfulServices;
    
    let references = [];
    // const Bold = ({ children }) => <span className="bold">{children}</span>
    // const Text = ({ children }) => <p className="align-center">{children}</p>
    const options = {    
        renderNode: {
            [BLOCKS.EMBEDDED_ASSET]: (node) => {
            const source = references.find(i => i.contentful_id === node.data.target.sys?.id);
            const fileType = ['image', 'video', 'audio'].find(i => source.file.contentType.includes(i));
            switch (fileType) {
                case "image":
                    return <img src={source.file.url} alt={source.description} />;
                case "video": 
                    return (
                        <video width="100%" height="240" controls>
                            <source src={source.file.url} type="video/mp4" />
                            Your browser does not support the video tag.
                        </video>
                    );
                case "audio": 
                    return <audio src={source.file.url} controls />;
            
                default:
                    return <h5>Missing asset</h5>
            }},
        },
    }
    return <section id="services" className="section-services">
        <div className="section-services__inner">
            <div className="grid grid--stretch-columns">
                <div className="section-services__service grid__col--3" data-sal="slide-down" data-sal-duration="500" data-sal-easing="ease">
                    <div className="section-services__img-container">
                        <div className="section-services__img-container-inner">
                            <img src={data?.service1Image?.file.url} className="section-services__img" />
                            
                        </div>
                    </div>
                    <h3 className="section-services__title">{data?.service1Title}</h3>
                    <div className="section-services__copy margin-bottom">{ documentToReactComponents(JSON.parse(data?.service1Copy.raw), options) }</div>
                </div>
                <div className="section-services__service grid__col--3" data-sal="slide-down" data-sal-duration="500" data-sal-easing="ease">
                    <div className="section-services__img-container">
                        <div className="section-services__img-container-inner">
                            <img src={data?.service2Image?.file.url} className="section-services__img" />
                        </div>
                    </div>
                    <h3 className="section-services__title">{data?.service2Title}</h3>
                    <div className="section-services__copy margin-bottom">{ documentToReactComponents(JSON.parse(data?.service2Copy.raw), options) }</div>
                </div>
                <div className="section-services__service grid__col--3" data-sal="slide-down" data-sal-duration="500" data-sal-easing="ease">
                    <div className="section-services__img-container">
                        <div className="section-services__img-container-inner">
                            <img src={data?.service3Image?.file.url} className="section-services__img" />
                        </div>
                    </div>
                    <h3 className="section-services__title">{data?.service3Title}</h3>
                    <div className="section-services__copy margin-bottom">{ documentToReactComponents(JSON.parse(data?.service3Copy.raw), options) }</div>
                </div>
                <div className="section-services__service grid__col--3" data-sal="slide-down" data-sal-duration="500" data-sal-easing="ease">
                    <div className="section-services__img-container">
                        <div className="section-services__img-container-inner">
                            <img src={data?.service4Image?.file.url} className="section-services__img" />
                        </div>
                    </div>
                    <h3 className="section-services__title">{data?.service4Title}</h3>
                    <div className="section-services__copy margin-bottom">{ documentToReactComponents(JSON.parse(data?.service4Copy.raw), options) }</div>
                </div>
            </div>
        </div>
    </section>
}


export default Services

