import React from "react";
import {useAboutContentQuery} from './about-content';
import { BLOCKS, MARKS } from "@contentful/rich-text-types"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";

const About = () => {
    const data = useAboutContentQuery().contentfulAbout;
    
    let references = [];
    const options = {    
        renderNode: {
            [BLOCKS.EMBEDDED_ASSET]: (node) => {
            const source = references.find(i => i.contentful_id === node.data.target.sys?.id);
            const fileType = ['image', 'video', 'audio'].find(i => source.file.contentType.includes(i));
            switch (fileType) {
                case "image":
                    return <img src={source.file.url} alt={source.description} />;
                case "video": 
                    return (
                        <video width="100%" height="240" controls>
                            <source src={source.file.url} type="video/mp4" />
                            Your browser does not support the video tag.
                        </video>
                    );
                case "audio": 
                    return <audio src={source.file.url} controls />;
            
                default:
                    return <h5>Missing asset</h5>
            }}
        },
    }
    return <section id="about" className="section-about" data-sal="slide-down" data-sal-duration="500" data-sal-easing="ease">
        <div className="section-about__inner margin-bottom">
            {documentToReactComponents(JSON.parse(data?.sectionContent.raw), options)}
        </div>
    </section>
}

export default About